.modal-dialog{
  max-width: 685px;
  h4{
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: $text-color;
    margin-bottom: 0;
  }
}

.modal-header{
  padding: 50px 60px 20px;
  border-bottom: none;
  .close{
    cursor: pointer;
    padding: 10px;
    margin: -32px -40px 0 0;
    svg{
      fill: $text-color;
      width: 16px;
      height: 16px;
      opacity: 1;
    }
    &:hover{
      svg{
        fill: $text-color-light;
        opacity: 1;
      }
    }
  }
}
.modal-body{
  padding: 20px 60px 60px;
}


.confirmation-modal{
  .close{
    cursor: pointer;
    padding: 10px;
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 2;
    svg{
      fill: $text-color;
      width: 16px;
      height: 16px;
      opacity: 1;
    }
    &:hover{
      svg{
        fill: $text-color-light;
        opacity: 1;
      }
    }
  }
  .modal-body{
    padding: 100px 125px 90px;
    p{
      margin-top: 22px;
      margin-bottom: 30px;
    }
  }
}


.alert-modal{
  .close{
    cursor: pointer;
    padding: 10px;
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 2;
    svg{
      fill: $text-color;
      width: 16px;
      height: 16px;
      opacity: 1;
    }
    &:hover{
      svg{
        fill: $text-color-light;
        opacity: 1;
      }
    }
  }
  .modal-body{
    padding: 90px 120px 50px;
    .icon-box{
      @include borderRadius(50%);
      width: 120px;
      height: 120px;
      background: $primary-color;
      position: relative;
      margin: 0 auto 25px;
      svg{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        fill: #fff;
        width: 38px;
        height: 28px;
      }
    }
    p{
      margin-top: 22px;
      margin-bottom: 30px;
    }
  }
}
