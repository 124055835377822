.bs-datepicker-container{
  padding: 0;
  .bs-datepicker-head{
    height: 40px;
    padding: 6px 10px;
    background-color: $primary-color;
    button{
      font-size: 13px;
    }
  }
  .bs-datepicker-body{
    table td.week span{
      color: $primary-color;
    }
    table td{
      color: $text-color;
    }

    table td span.selected,
    table td.selected span,
    table td span[class*="select-"]:after,
    table td[class*="select-"] span:after {
      background-color: $primary-color;
    }
  }
}
