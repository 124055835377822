
@mixin borderRadius($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}

@mixin rotate($value) {
  -webkit-transform: rotate($value);
  -moz-transform: rotate($value);
  -ms-transform: rotate($value);
  -o-transform: rotate($value);
  transform: rotate($value);
}

@mixin transition($what, $time, $how, $delay) {
  -webkit-transition: $what $time $how $delay;
  -moz-transition: $what $time $how $delay;
  -o-transition: $what $time $how $delay;
  transition: $what $time $how $delay;
}
