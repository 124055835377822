// Scss variables

$text-color: #413B5C;
$form-text-color: #413B5C;
$text-color-light: #7F8398;

$primary-color: #F77F00;
$primary-color-hover: #ff9c30;

$danger-color: #FFEDF2;
$danger-text-color: #FD397A;

$success-color: #EAF8F4;
$success-text-color: #0BBB87;

$info-color: #E1EFFF;
$info-text-color: #3594FF;


$warning-color: #FFE9D1;
$warning-color-hover: #f8d6a3;
$warning-text-color: #F77F00;
