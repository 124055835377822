table.table{
  tr{
    th{
      color: $text-color;
      background: #F5F6FA;
      height: 80px;
      border: none;
      vertical-align: middle;
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;
      padding: 5px 30px;
      &:first-child{
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child{
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    td{
      border-top: none;
      border-bottom: 1px solid #E6E7EB;
      height: 60px;
      vertical-align: middle;
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
      padding: 5px 30px;
      color: $text-color-light;
      span.action{
        color: $text-color-light;
        font-weight: 600;
        cursor: pointer;
        &:hover{
          color: $primary-color;
        }
      }
      button.action{
        background: none;
        box-shadow: none;
        border: none;
        padding: 2px 0;
        margin: 0;
        color: $text-color-light;
        font-weight: 600;
        cursor: pointer;
        &:hover{
          color: $primary-color;
        }
      }
    }
  }
}

.table-form-header-box{
  padding: 0 30px;
  position: relative;
}

table.table-form{
  margin-bottom: 0;
  tr{
    td{
      padding: 1px;
      border-right: 1px solid #E6E7EB;
      min-width: 140px;
      &:last-child{
        border-right: none;
        width: 158px;
        padding: 5px 30px;
      }
      input{
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        padding: 0 30px;
        color: $text-color-light;
      }
    }
  }
}
