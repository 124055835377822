/* You can add global styles to this file, and also import other style files */

/** npm imports **/
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";

/** scss imports **/
@import "assets/scss/helper";
@import "assets/scss/typography";
@import "assets/scss/form";
@import "assets/scss/tab";
@import "assets/scss/table";
@import "assets/scss/modal";
@import "assets/scss/datepicker";

body{
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  &.disable-scroll{
    overflow: hidden;
  }
}

.mr-20{
  margin-right: 20px;
}

.header{
  margin-bottom: 70px;
  margin-right: 30px;
  margin-left: 30px;
  h4{
    font-size: 20px;
    color: $text-color;
    font-weight: 600;
    margin: 0;
    line-height: 44px;
  }
  a{
    font-size: 13px;
    text-decoration: none;
    line-height: 20px;
    font-weight: 400;
    color: $text-color;
    cursor: pointer;
    svg{
      width: 6px;
      height: 11px;
      margin-right: 6px;
      position: relative;
      top:-1px;
      fill: $text-color;
    }
    &:hover{
      color: $primary-color;
      svg{
        fill: $primary-color;
      }
    }
  }
  &.border-bottom{
    position: relative;
    padding-bottom: 55px;
    margin-bottom: 40px;
    border: none !important;
    &::after{
      content: '';
      display: block;
      background: #E6E7EB;
      height: 1px;
      bottom: 0;
      width: 100%;
      position: absolute;
    }
  }
  .close{
    cursor: pointer;
    padding: 10px;
    opacity: 1;
    svg{
      fill: $text-color;
      width: 16px;
      height: 16px;
      opacity: 1;
    }
    &:hover{
      svg{
        fill: $text-color-light;
        opacity: 1;
      }
    }
  }
}

.content-body{
  height: calc(100vh - 114px - 120px);
  overflow: hidden;
  padding-right: 30px;
  padding-left: 30px;
  position: relative;
}

.invalid-feedback{
  display: block;
  color: $danger-text-color;
  font-size: 13px;
  font-weight: 400;
  margin-top: 2px;
}

.panel{
  @include borderRadius(8px);
  box-shadow:  0 4px 24px 0 rgba(219,219,219,0.5);
  padding: 50px 120px 60px;
  box-sizing: border-box;
}

.alert{
  border: none;
  font-size:13px;
  line-height: 24px;
  font-weight: 400;
  padding: 9px 20px;
  &.alert-danger{
    background: $danger-color;
    color: $danger-text-color;
  }
  &.alert-success{
    background: $success-color;
    color: $success-text-color;
  }
}

.badge{
  @include borderRadius(4px);
  line-height: 32px;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  padding: 0 20px;
  height: 32px;
  &.badge-success{
    background: $success-color;
    color: $success-text-color;
  }
  &.badge-danger{
    background: $danger-color;
    color: $danger-text-color;
  }
  &.badge-info{
    background: $info-color;
    color: $info-text-color;
  }
}

.or-divider{
  position: relative;
  .text{
    width: 20px;
    height: 20px;
    background: #fff;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #BDC0D0;
    position: relative;
    display: block;
    text-align: center;
    margin: auto;
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 1px;
    background: #E6E7EB;
  }
}

// Card

.card{
  margin-bottom: 30px;
  box-shadow: 0 4px 12px 0 rgba(146,146,146,0.2);
  @include borderRadius(8px);;
  border: none;
  padding: 30px;
  h5{
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 600;
    color: $text-color;
  }
  p{
    font-size: 13px;
    margin-bottom: 0;
    line-height: 24px;
    color: $text-color-light;
    font-weight: 400;
  }
  .card-header{
    padding: 0 0 10px 0;
    background: 0;
    border-bottom: 1px solid #E6E7EB;
  }
  .card-body{
    padding: 0;
    .btn{
      margin-top: 20px;
    }
  }
  .card-footer{
    padding: 0;
    background: none;
    border: none;
    .btn{
      margin-top: 30px;
    }
  }
}

// Scrollbar

.ps__rail-y{
  background: #dddee1 !important;
  width: 4px !important;
  @include borderRadius(0);
  opacity: 1 !important;
  .ps__thumb-y{
    opacity: 1 !important;
    width: 4px !important;
    right: 0 !important;
    background: #7a7d8b;
    @include borderRadius(0);
    cursor: pointer;
  }
}
.ps__rail-x{
  background: #dddee1 !important;
  height: 5px !important;
  @include borderRadius(0);
  opacity: 1 !important;
  .ps__thumb-x{
    opacity: 1 !important;
    height: 5px !important;
    top: 0 !important;
    background: #7a7d8b;
    @include borderRadius(0);
    cursor: pointer;
  }
}
