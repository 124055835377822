

.form-group{
  &.mb-lg{
    margin-bottom: 40px;
  }
}
.form-group-btn{
  margin-top: 40px;
}

label{
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  color: $form-text-color;
  &.disabled{
    color: #BDC0D0;
  }
}
.input-group{
  .form-control{
    border-right: none;
    transition: none;
    &:focus + .input-group-append .input-group-text {
      border-color: #BDC0D0;
    }
  }
  .input-group-append {
    border-color: #E6E7EB;
    .input-group-text {
      transition: none;
      background: #fff;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-color: #E6E7EB;
      border-left: none;
      position: relative;

      svg {
        width: 12px;
        height: 12px;
        fill: #BDC0D0;
      }

      &.close {
        cursor: pointer;
        opacity: 1;
        svg {
          fill: $text-color;
        }

        &:hover {
          svg {
            fill: $primary-color;
          }
        }
      }
    }
  }
}

textarea.form-control{
  min-height: 140px;
}
.form-control{
  background: #fff;
  border: 1px solid #E6E7EB;
  @include borderRadius(6px);
  padding: 10px 20px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: $form-text-color;
  height: 44px;
  &:focus {
    color: $form-text-color;
    background-color: #fff;
    border-color: #BDC0D0;
    outline: 0;
    box-shadow: none;
  }
  &:disabled, &.disabled{
    color: #BDC0D0;
    background: #f1f1f1;
    border: 1px solid #E6E7EB;
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.ng-select {
  &.ng-select-single .ng-select-container,
  &.ng-select-multiple .ng-select-container{
    background: #fff;
    border: 1px solid #E6E7EB !important;
    box-shadow: none !important;
    @include borderRadius(6px);
    padding: 0px 5px 0 20px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    min-height: 44px;
    cursor: pointer;
    .ng-value-container {
      padding-left: 0;
      .ng-placeholder {
        color: $text-color-light;
      }
    }
    &:focus {
      color: $form-text-color;
      background-color: #fff;
      outline: 0;
      box-shadow: none;
    }
  }

  &.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
    background: #f1f1f1;
    .ng-value-icon{
      border-right: 1px solid #e6e7eb !important;
      &:hover{
        background: #e6e7eb;
      }
    }
  }
  &.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 10px;
    padding-left: 15px;
  }

  &.ng-select-disabled{
    .ng-select-container{
      color: #BDC0D0;
      background: none;
      border: 1px solid #E6E7EB;
      cursor: not-allowed;
      opacity: 0.8;
    }
  }

  &.ng-select-opened.ng-select-bottom>.ng-select-container {
    @include borderRadius(6px);
  }

  // ng dropdown
  .ng-dropdown-panel{
    border: none;
    background: #fff;
    @include borderRadius(8px);
    box-shadow: 0 2px 10px 0 rgba(219, 219, 219, 0.7);
    width: 100%;
    margin: 0;
    .ng-dropdown-panel-items{
      padding: 10px 20px;
    }
    .ng-dropdown-panel-items .ng-option {
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
      color: $form-text-color;
      padding: 12px 0;
      border-bottom: 1px solid #E6E7EB;
      &.ng-option-marked{
        color: $form-text-color;
        background: none;
      }
      &.ng-option-selected{
        background-color: transparent;
        color: $primary-color;
      }
      &:last-child{
        border-bottom: none;
      }
      &.ng-option-disabled{
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }
  &.cargo-ng-select{
    .ng-dropdown-panel{
      width: auto !important;
    }
  }
}



.btn-link{
  color: $primary-color;
  font-size: 13px;
  line-height: 20px;
  padding: 0;
  font-weight: 600;
  font-style: italic;
  border-bottom: 1px solid $primary-color;
  &:hover{
    color: $primary-color-hover;
    border-bottom: 1px solid $primary-color-hover;
    text-decoration: none;
  }
  &:focus,
  &:active{
    text-decoration: none !important;
  }
}


.btn {
  @include borderRadius(6px);
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  min-width: 200px;
  height: 44px;
  border: 1px solid transparent;
  box-shadow: none !important;
  &.btn-sm{
    min-width: 120px;
    height: 32px;
  }
}

.btn-primary{
  background: $primary-color;
  border-color: $primary-color;
  color: #fff;
  &:hover{
    background: $primary-color-hover;
    border-color: $primary-color-hover;
  }
  &.focus,
  &:focus{
    box-shadow: none !important;
    background: $primary-color;
    border-color: $primary-color;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active{
    box-shadow: none !important;
    background: $primary-color-hover;
    border-color: $primary-color-hover;
  }
  &.disabled,
  &:disabled{
    background: #F5F6FA;
    border-color: #F5F6FA;
    color: #BDC0D0;
    cursor: not-allowed;
    &:hover{
      background: #F5F6FA;
      border-color: #F5F6FA;
      color: #BDC0D0;
    }
  }
}

.btn-warning{
  background: $warning-color;
  border-color: $warning-color;
  color: $warning-text-color;
  &:hover{
    background: $warning-color-hover;
    border-color: $warning-color-hover;
    color: $warning-text-color;
  }
  &.focus,
  &:focus{
    box-shadow: none !important;
    background: $warning-color;
    border-color: $warning-color;
    color: $warning-text-color;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active{
    box-shadow: none !important;
    background: $warning-color-hover;
    border-color: $warning-color-hover;
    color: $warning-text-color;
  }
  &.disabled,
  &:disabled{
    background: #F5F6FA;
    border-color: #F5F6FA;
    color: #BDC0D0;
    cursor: not-allowed;
    &:hover{
      background: #F5F6FA;
      border-color: #F5F6FA;
      color: #BDC0D0;
    }
  }
}
.btn-outline-primary{
  background: transparent;
  border-color: $primary-color;
  color: $text-color;
  &:hover{
    background: $warning-color-hover;
    border-color: $primary-color;
    color: $text-color;
  }
  &.focus,
  &:focus{
    box-shadow: none !important;
    background: transparent;
    border-color: $primary-color;
    color: $text-color;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active{
    box-shadow: none !important;
    background: $warning-color-hover;
    border-color: $warning-color-hover;
    color: $warning-text-color;
  }
  &.disabled,
  &:disabled{
    &:hover{
      background: transparent !important;
      border-color: $primary-color !important;
      color: $primary-color !important;
      cursor: not-allowed !important;
      opacity: 0.8 !important;
    }
  }
}
.btn-outline-dark{
  background: none;
  border-color: #7F8398;
  color: $text-color;
  &:hover{
    background: rgba(127, 131, 152, 0.15);
    border-color: $text-color;
    color: $text-color;
  }
  &.focus,
  &:focus{
    box-shadow: none !important;
    background: transparent;
    border-color:  #7F8398;
    color: $text-color;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active{
    box-shadow: none !important;
    background: none;
    border-color: #7F8398;
    color: #7F8398;
  }
}


.btn-link-light{
  color: $text-color-light;
  padding: 0;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  height: auto;
  margin: 0;
  svg{
    fill: $text-color-light;
    width: 10px;
    height: 10px;
    position: relative;
    top: -1px;
    margin-right: 10px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  &:hover{
    color: $text-color;
    svg{
      fill: $text-color;
    }
  }
  &:focus,
  &:active{
    text-decoration: none !important;
  }
  &:disabled{
    cursor: not-allowed;
    color: $text-color-light;
    opacity: 0.8;
  }
}



.link-dropdown{
  a{
    font-size: 13px;
    line-height: 20px;
    color: #74788D;
    font-weight: 400;
    padding: 0;
  }
  &.dropup .dropdown-toggle::after{
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
  &.open.dropup .dropdown-toggle::after{
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent;
  }

  .dropdown-menu {
    border: none;
    @include borderRadius(8px);
    background-color: #FFFFFF;
    box-shadow: 0 4px 12px 0 rgba(146,146,146,0.2);
    padding: 8px 27px;
    li{
      padding: 7px 0;
      font-size: 13px;
      line-height: 20px;
      color: #74788D;
      border-bottom: 1px solid #E6E7EB;
      font-weight: 400;
      cursor: pointer;
      position: relative;
      &:last-child{
        border-bottom: none;
      }
      &.active{
        color: $primary-color;
        font-weight: 600;
        svg{
          display: block;
        }
      }
      &:hover{
        color: $primary-color;
      }
      svg{
        display: none;
        fill: $primary-color;
        width: 12.5px;
        height: 9.5px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}

.dropdown-btn{
  .dropdown-menu {
    border: none;
    @include borderRadius(8px);
    background-color: #FFFFFF;
    box-shadow: 0 4px 12px 0 rgba(146,146,146,0.2);
    padding: 8px 27px;
    li{
      padding: 7px 0;
      font-size: 13px;
      line-height: 20px;
      color: #74788D;
      font-weight: 600;
      cursor: pointer;
      position: relative;
      &:hover{
        color: $primary-color;
      }
    }
  }
}




.form-group-array {
  border-bottom: 1px solid #E6E7EB;
  padding-bottom: 45px;
  margin-bottom: 45px;
  .form-title-array {
    margin-bottom: 35px;

    h6 {
      margin-bottom: 0;
      color: $text-color;
      font-size: 16px;
      line-height: 23px;
      font-weight: 600;
    }

    .delete-icon {
      width: 13px;
      height: 14px;
      fill: #BDC0D0;
      cursor: pointer;
      float: right;

      &:hover {
        fill: $primary-color;
      }
    }
  }
  &:last-child{
    margin-bottom: 0;
  }
}


input[type="radio"] {
  display: none;
}
input[type="radio"] {
  + span {
    position: relative;
    padding-left: 26px;
    margin-right: 30px;
    cursor: pointer;
    &:before {
      content: ' ';
      width: 16px;
      height: 16px;
      @include borderRadius(50%);
      background: none;
      border: 1px solid #BDC0D0;
      position: absolute;
      left: 0;
      top: 2px;
    }
    &:after {
      opacity: 0;
      width: 4px;
      height: 4px;
      position: absolute;
      top: 8px;
      left: 6px;
      @include borderRadius(50%);
    }
  }
  &:checked {
    + span {
      &:before {
        content: "";
        background: $primary-color;
        border: 1px solid $primary-color;
      }

      &:after {
        content: "";
        opacity: 1;
        background: #fff;
      }
    }
  }
}


input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] {
  + span {
    position: relative;
    padding-left: 26px;
    margin-right: 30px;
    cursor: pointer;
    &:before {
      content: ' ';
      width: 16px;
      height: 16px;
      @include borderRadius(3px);
      background: none;
      border: 1px solid #BDC0D0;
      position: absolute;
      left: 0;
      top: 2px;
    }
    &:after {
      opacity: 0;
      height: 14px;
      width: 14px;
      position: absolute;
      left: 0;
      top: 2px;
    }
  }
  &:checked {
    + span {
      &:before {
        content: "";
        background: $primary-color;
        border: 1px solid $primary-color;
      }

      &:after {
        content: "";
        opacity: 1;
        background: url('../images/icons/checked.svg') no-repeat;
        fill: #fff;
        top: 7px;
        width: 8px;
        height: 8px;
        left: 4px;
      }
    }
  }
}
