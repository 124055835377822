h3{
  font-size: 20px;
  color: $text-color;
  line-height: 30px;
  font-weight: 600;
}

p{
  font-size: 13px;
  line-height: 20px;
  font-weight: 300;
  color: #74788D;
}
